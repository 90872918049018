<template>
  <div>
    <h1>Reset Password</h1>
    <b-card border-variant="light" bg-variant="light" class="text-center">
      <b-form @submit="submit" @reset="onReset" id="reset-pwd-form">
        <b-card-body>
          <b-alert :show="error !== ''" variant="danger">{{ error }}</b-alert>
          <b-row class="my-2">
            <b-col md="3" offset-md="2">
              <label for="password-live">Password:</label>
            </b-col>
            <b-col md="5">
              <b-form-input
                id="password-live"
                :state="passwordRule"
                v-model="password"
                type="password"
                aria-required="true"
                placeholder="Enter Password"
                required
              ></b-form-input>
              <b-form-invalid-feedback id="password-feedback">
                Password should be minimum 8 characters long with
                <strong>alphabet, special charecter</strong> and
                <strong>number</strong>.
              </b-form-invalid-feedback>
            </b-col>
          </b-row>

          <b-row class="my-2">
            <b-col md="3" offset-md="2">
              <label for="confirm-pwd-live">Confirm password:</label>
            </b-col>
            <b-col md="5">
              <b-form-input
                id="confirm-pwd-live"
                :state="confirmPwdRule"
                v-model="passwordConfirmed"
                type="password"
                aria-required="true"
                placeholder="Confirm Password"
                required
              ></b-form-input>
              <b-form-invalid-feedback id="confirm-pwd-live-feedback">
                Password is not matching
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <br />
          <div class="my-4">
            <b-button type="submit" variant="primary" class="mx-4"
              >Submit</b-button
            >
            <b-button type="reset" variant="danger" class="mx-4"
              >Cancel</b-button
            >
          </div>
        </b-card-body>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  BAlert,
  BCard,
  BForm,
  BCardBody,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";

export default {
  name: "PasswordReset",

  components: {
    BAlert,
    BCard,
    BForm,
    BCardBody,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
  },

  data() {
    return {
      userId: "",
      token: "",
      passwordConfirmed: "",
      password: "",
      error: "",
    };
  },

  computed: {
    passwordRule() {
      return (
        this.password &&
        // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/.test(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&+#])[A-Za-z\d@$!%*?&+#]{8,16}$/.test(
          this.password
        )
      );
    },

    confirmPwdRule() {
      return this.password === this.passwordConfirmed;
    },
  },

  created() {
    this.userId = this.$route.query.id;
    this.token = this.$route.query.token;
  },

  methods: {
    ...mapActions(["resetPassword", "signOut"]),

    onReset() {
      this.password = "";
      this.passwordConfirmed = "";
      this.userId = "";
      this.token = "";
      this.error = "";
      this.$router.push("/login");
    },

    async submit(event) {
      event.preventDefault();
      if (!this.passwordRule || !this.confirmPwdRule) {
        return;
      }
      try {
        await this.resetPassword({
          userId: this.userId,
          token: this.token,
          password: this.password,
        });

        this.$bvToast.toast(`Password Changed Successfully`, {
          title: "Success",
          autoHideDelay: 2000,
          variant: `warning`,
          solid: true,
        });

        setTimeout(() => this.$router.push("/login"), 2000);
      } catch (e) {
        console.error(e);
        this.error = e.response.data.message;
        if (e.response.status === 401) {
          this.signOut();
        }
      }
    },
  },
};
</script>
